/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Account } from './Account';
import {
    AccountFromJSON,
    AccountFromJSONTyped,
    AccountToJSON,
} from './Account';
import type { LoyaltyPerks } from './LoyaltyPerks';
import {
    LoyaltyPerksFromJSON,
    LoyaltyPerksFromJSONTyped,
    LoyaltyPerksToJSON,
} from './LoyaltyPerks';
import type { LoyaltyAccountLoyaltyRequest } from './LoyaltyAccountLoyaltyRequest';
import {
    LoyaltyAccountLoyaltyRequestFromJSON,
    LoyaltyAccountLoyaltyRequestFromJSONTyped,
    LoyaltyAccountLoyaltyRequestToJSON,
} from './LoyaltyAccountLoyaltyRequest';
import type { LoyaltyRedemptionValue } from './LoyaltyRedemptionValue';
import {
    LoyaltyRedemptionValueFromJSON,
    LoyaltyRedemptionValueFromJSONTyped,
    LoyaltyRedemptionValueToJSON,
} from './LoyaltyRedemptionValue';
import type { LoyaltyProgramEnum } from './LoyaltyProgramEnum';
import {
    LoyaltyProgramEnumFromJSON,
    LoyaltyProgramEnumFromJSONTyped,
    LoyaltyProgramEnumToJSON,
} from './LoyaltyProgramEnum';

/**
 * 
 * @export
 * @interface LoyaltyAccount
 */
export interface LoyaltyAccount {
    /**
     * 
     * @type {string}
     * @memberof LoyaltyAccount
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyAccount
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyAccount
     */
    brandName?: string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyAccount
     */
    brandType?: string;
    /**
     * 
     * @type {LoyaltyProgramEnum}
     * @memberof LoyaltyAccount
     */
    loyaltyProgram?: LoyaltyProgramEnum;
    /**
     * the user's unique Loyalty ID associated with the loyalty program.  Choose the unmasked ID if possible.'
     * @type {string}
     * @memberof LoyaltyAccount
     */
    loyaltyId?: string;
    /**
     * the user's masked loyalty id'
     * @type {string}
     * @memberof LoyaltyAccount
     */
    redactedLoyaltyId?: string;
    /**
     * the user's current loyalty balance
     * @type {number}
     * @memberof LoyaltyAccount
     */
    loyaltyPoints?: number;
    /**
     * the user's current loyalty status
     * @type {string}
     * @memberof LoyaltyAccount
     */
    loyaltyStatus?: string;
    /**
     * the user's name associated with the loyalty program.  None if not found.
     * @type {string}
     * @memberof LoyaltyAccount
     */
    loyaltyUserName?: string;
    /**
     * whether or not this information came from an account statement email
     * @type {boolean}
     * @memberof LoyaltyAccount
     */
    accountStatementsEnabled?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof LoyaltyAccount
     */
    lastUpdated?: Date;
    /**
     * whether or not this loyalty account is currently being parsed
     * @type {boolean}
     * @memberof LoyaltyAccount
     */
    parseInProgress?: boolean;
    /**
     * 
     * @type {LoyaltyPerks}
     * @memberof LoyaltyAccount
     */
    loyaltyPerks?: LoyaltyPerks;
    /**
     * 
     * @type {LoyaltyRedemptionValue}
     * @memberof LoyaltyAccount
     */
    loyaltyRedemptionValue?: LoyaltyRedemptionValue;
    /**
     * the cash value of the loyalty points
     * @type {number}
     * @memberof LoyaltyAccount
     */
    cashValue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyAccount
     */
    programTierIndex?: number;
    /**
     * the point change from the last update
     * @type {number}
     * @memberof LoyaltyAccount
     */
    pointsUpdate?: number;
    /**
     * the number of days since the last point update
     * @type {number}
     * @memberof LoyaltyAccount
     */
    pointsUpdateDays?: number;
    /**
     * 
     * @type {LoyaltyAccountLoyaltyRequest}
     * @memberof LoyaltyAccount
     */
    loyaltyRequest?: LoyaltyAccountLoyaltyRequest;
    /**
     * 
     * @type {Account}
     * @memberof LoyaltyAccount
     */
    account?: Account;
    /**
     * This is applicable to credit cards only. When it's true, the user needs to re-sync with MX.
     * @type {boolean}
     * @memberof LoyaltyAccount
     */
    requiresResync?: boolean;
}

/**
 * Check if a given object implements the LoyaltyAccount interface.
 */
export function instanceOfLoyaltyAccount(value: object): value is LoyaltyAccount {
    return true;
}

export function LoyaltyAccountFromJSON(json: any): LoyaltyAccount {
    return LoyaltyAccountFromJSONTyped(json, false);
}

export function LoyaltyAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoyaltyAccount {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'userId': json['user_id'] == null ? undefined : json['user_id'],
        'brandName': json['brand_name'] == null ? undefined : json['brand_name'],
        'brandType': json['brand_type'] == null ? undefined : json['brand_type'],
        'loyaltyProgram': json['loyalty_program'] == null ? undefined : LoyaltyProgramEnumFromJSON(json['loyalty_program']),
        'loyaltyId': json['loyalty_id'] == null ? undefined : json['loyalty_id'],
        'redactedLoyaltyId': json['redacted_loyalty_id'] == null ? undefined : json['redacted_loyalty_id'],
        'loyaltyPoints': json['loyalty_points'] == null ? undefined : json['loyalty_points'],
        'loyaltyStatus': json['loyalty_status'] == null ? undefined : json['loyalty_status'],
        'loyaltyUserName': json['loyalty_user_name'] == null ? undefined : json['loyalty_user_name'],
        'accountStatementsEnabled': json['account_statements_enabled'] == null ? undefined : json['account_statements_enabled'],
        'lastUpdated': json['last_updated'] == null ? undefined : (new Date(json['last_updated'])),
        'parseInProgress': json['parse_in_progress'] == null ? undefined : json['parse_in_progress'],
        'loyaltyPerks': json['loyalty_perks'] == null ? undefined : LoyaltyPerksFromJSON(json['loyalty_perks']),
        'loyaltyRedemptionValue': json['loyalty_redemption_value'] == null ? undefined : LoyaltyRedemptionValueFromJSON(json['loyalty_redemption_value']),
        'cashValue': json['cash_value'] == null ? undefined : json['cash_value'],
        'programTierIndex': json['program_tier_index'] == null ? undefined : json['program_tier_index'],
        'pointsUpdate': json['points_update'] == null ? undefined : json['points_update'],
        'pointsUpdateDays': json['points_update_days'] == null ? undefined : json['points_update_days'],
        'loyaltyRequest': json['loyalty_request'] == null ? undefined : LoyaltyAccountLoyaltyRequestFromJSON(json['loyalty_request']),
        'account': json['account'] == null ? undefined : AccountFromJSON(json['account']),
        'requiresResync': json['requires_resync'] == null ? undefined : json['requires_resync'],
    };
}

export function LoyaltyAccountToJSON(value?: LoyaltyAccount | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'user_id': value['userId'],
        'brand_name': value['brandName'],
        'brand_type': value['brandType'],
        'loyalty_program': LoyaltyProgramEnumToJSON(value['loyaltyProgram']),
        'loyalty_id': value['loyaltyId'],
        'redacted_loyalty_id': value['redactedLoyaltyId'],
        'loyalty_points': value['loyaltyPoints'],
        'loyalty_status': value['loyaltyStatus'],
        'loyalty_user_name': value['loyaltyUserName'],
        'account_statements_enabled': value['accountStatementsEnabled'],
        'last_updated': value['lastUpdated'] == null ? undefined : ((value['lastUpdated']).toISOString()),
        'parse_in_progress': value['parseInProgress'],
        'loyalty_perks': LoyaltyPerksToJSON(value['loyaltyPerks']),
        'loyalty_redemption_value': LoyaltyRedemptionValueToJSON(value['loyaltyRedemptionValue']),
        'cash_value': value['cashValue'],
        'program_tier_index': value['programTierIndex'],
        'points_update': value['pointsUpdate'],
        'points_update_days': value['pointsUpdateDays'],
        'loyalty_request': LoyaltyAccountLoyaltyRequestToJSON(value['loyaltyRequest']),
        'account': AccountToJSON(value['account']),
        'requires_resync': value['requiresResync'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MessagesPostRequestMessage
 */
export interface MessagesPostRequestMessage {
    /**
     * Base64-encoded message data
     * @type {string}
     * @memberof MessagesPostRequestMessage
     */
    data: string;
    /**
     * The ID of the message
     * @type {string}
     * @memberof MessagesPostRequestMessage
     */
    messageId: string;
    /**
     * The time the message was published
     * @type {Date}
     * @memberof MessagesPostRequestMessage
     */
    publishTime: Date;
}

/**
 * Check if a given object implements the MessagesPostRequestMessage interface.
 */
export function instanceOfMessagesPostRequestMessage(value: object): value is MessagesPostRequestMessage {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('messageId' in value) || value['messageId'] === undefined) return false;
    if (!('publishTime' in value) || value['publishTime'] === undefined) return false;
    return true;
}

export function MessagesPostRequestMessageFromJSON(json: any): MessagesPostRequestMessage {
    return MessagesPostRequestMessageFromJSONTyped(json, false);
}

export function MessagesPostRequestMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessagesPostRequestMessage {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'],
        'messageId': json['messageId'],
        'publishTime': (new Date(json['publishTime'])),
    };
}

export function MessagesPostRequestMessageToJSON(value?: MessagesPostRequestMessage | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': value['data'],
        'messageId': value['messageId'],
        'publishTime': ((value['publishTime']).toISOString()),
    };
}

